import React, { Suspense, useEffect, useState, useRef } from "react"
import { Outlet,useLocation } from "react-router-dom"
import api from './api'
import { lang, getTextSize, cookies } from './lang'
import th_icon from '../assets/th.jpg';
import en_icon from '../assets/en.jpg';
import logo from '../assets/main-logo.png';
import Footer from './Footer'
import ScrollToTop from './ScrollToTop';
import { FaPhoneAlt, FaFacebookF, FaEnvelope, FaTwitter, FaAlignRight, FaChevronDown, FaYoutube, FaTiktok } from 'react-icons/fa';
import { BsLine } from "react-icons/bs";
import footer_styles from '../assets/css/footer.module.css';
import CookieBanner from './CookieBanner';  
import Preloader from './Template/Preloader'
// import ReactGA, { GA4 } from "react-ga4";

const Layout = () => {
    const [menus, setMenus] = useState([]);
    const [init, setInit] = useState([]);
    const [language, setLang] = useState("th");
    const [font_size, setFontSize] = useState("small");
    const [mobile_menu, setMobileMenu] = useState(false);
    const [chk_menu, setChkMenu] = useState(1);
    const [header_color, setHeaderColor] = useState("#fff");
    const [bg_color, setBGColor] = useState("#fff");
    const location = useLocation();
    const header_ref = useRef(null);
    const body_ref = useRef(null);
  
   

    useEffect(()=>{
        setLang(prev => localStorage.getItem("lang"));
        const fetchMenus = async () =>{
          try {
            let data = {
              type: "main-menu",
              order: "parent_menu_id,order asc"
            }
            const response = await api.post('/menu',data);

            let menu = [];
            let menu_index = [];
            response.data.results.forEach(item => {
                if(!menu_index.includes(item.id)){
                  menu_index.push(item.id);
                }
                let menu_active = false;
                if(location.pathname===item.link){
                  menu_active = true;
                  if(menu[menu_index.indexOf(item.parent_menu_id)]){
                    menu[menu_index.indexOf(item.parent_menu_id)].active = true;
                  }
                }
                if(item.parent_menu_id==="0"){
                  menu[menu_index.indexOf(item.id)] = {
                    menu_id: item.id,
                    menu_th: item.menu_th,
                    menu_en: item.menu_en,
                    link: item.link,
                    menu_type: item.menu_type,
                    order: item.order,
                    target: item.target,
                    active: menu_active,
                    show: menu_active,
                    child: []
                  }
                }else{
                  let tmp = {
                    menu_id: item.id,
                    menu_th: item.menu_th,
                    menu_en: item.menu_en,
                    link: item.link,
                    menu_type: item.menu_type,
                    order: item.order,
                    target: item.target,
                    active: menu_active,
                    show: menu_active,
                    child: []
                  };
                  menu[menu_index.indexOf(item.parent_menu_id)].child.push(tmp);
                }
            });
            // console.log(menu);
            setMenus(menu);
          } catch (err) {
              if(err.response){
                  //Not in 202 response range
                  console.log(err.response.data);
                  console.log(err.response.headers);
                  console.log(err.response.status);
              } else {
                  console.log(`Error: ${err.message}`);
              }
          }
  
          try {
            const response = await api.get('/setting');
            setInit(response.data.results);
            const home_config = JSON.parse(response.data.results.config);
            setHeaderColor(home_config.header_color);
            setBGColor(home_config.bg_color);
            // console.log(home_config.header_color);
            // console.log(home_config.bg_color);
          } catch (err) {
              if(err.response){
                  //Not in 202 response range
                  console.log(err.response.data);
                  console.log(err.response.headers);
                  console.log(err.response.status);
              } else {
                  console.log(`Error: ${err.message}`);
              }
          }
        }
        fetchMenus();

        let text_size = cookies.get('text-size');
        setFontSize(text_size);
    },[]);

    window.addEventListener('scroll', (event) => {
      if(header_ref.current){
        if (window.scrollY > 5) {
          header_ref.current.className = "drop-shadow";
        } else {
          header_ref.current.className = "";
        }
      }
    });

    const setLanguage = (lang) => {
        setLang(prev => lang);
        localStorage.setItem("lang", lang);
        window.location.reload(false);
    }

    const setFont = (text_size) => {
      setFontSize(prev => text_size);
      cookies.set('text-size', text_size, { path: '/' });
      body_ref.current.ClassName = getTextSize();
    }

    const setActive = (e,menu_id) => {
      setMenus(prev => {
          let tmp = prev;
          tmp.forEach(item => {
              item.active = false;
                if(item.menu_id === menu_id){
                    item.active = true;
                    item.show = true;
                }
                let submenus = item.child;
                submenus.forEach(element => {
                    element.active = false;
                    if(element.menu_id === menu_id){
                        item.active = true;
                        element.active = true;
                        element.show = true;
                    }
                });
          });
          return tmp;
      });
      setChkMenu(menu_id);
    }

    return (
      <div ref={body_ref} className={getTextSize()}>
      <header ref={header_ref} className="shadow-none">
          {(init.name_th!==undefined)?
          <nav>
            <div className="container">
                <div className="logo">
                  <div className="logo-img">
                  <a href={process.env.PUBLIC_URL + "/"}><img src={logo} alt={lang(init.name_th,init.name_en)} height="75.5"/></a>
                  </div>
                  <div className="top-nav">
                    <div className="tool">
                      <ul className="language">
                          <li className={language==="th"?'active':''}><button onClick={()=>setLanguage("th")}>TH</button></li>
                          <li className={language==="en"?'active':''}><button onClick={()=>setLanguage("en")}>EN</button></li>
                      </ul>
                      <ul className="font-size">
                          <li className={font_size==="small"?'active':''}><button onClick={()=>setFont("small")}>ก</button></li>
                          <li className={font_size==="normal"?'active':''}><button onClick={()=>setFont("normal")}>ก</button></li>
                          <li className={font_size==="large"?'active':''}><button onClick={()=>setFont("large")}>ก</button></li>
                      </ul>
                    </div>
                    <div className="menu">
                      <div className="mobile-menu">
                        <button onClick={e => setMobileMenu(true)} className="mobile-nav-open">
                        <FaAlignRight/>
                        </button>
                        <div className={mobile_menu?'mobile-nav active':'mobile-nav'} style={{"background":header_color}}>
                          <ul className="top">
                            <li>
                              <a href={'#close'} onClick={e => setMobileMenu(false)}>X</a>
                            </li>
                            <li className={"mobile-menu-language group"}>
                                {language==="th"?(<div><img src={th_icon} alt="ภาษาไทย" width="36" height="15" /> ไทย <FaChevronDown /></div>):''}
                                {language==="en"?(<div><img src={en_icon} alt="ENGLISH" width="36" height="15" /> Eng <FaChevronDown /></div>):''}
                                <div className="submenu invisible group-hover:visible">
                                  <ul className="">
                                    <li><button onClick={()=>setLanguage("th")}><img src={th_icon} alt="ภาษาไทย" width="36" height="15" /> ภาษาไทย</button></li>
                                    <li><button onClick={()=>setLanguage("en")}><img src={en_icon} alt="ENGLISH" width="36" height="15" /> ENGLISH</button></li>
                                  </ul>
                                </div>
                            </li>
                          </ul>
                          <ul className="ul-menu-nav">
                          {menus.map((menu, key) =>(
                              <li key={"mobile-menu"+key} className={menu.active?"active group":'group'}>
                              <a href={menu.link} onClick={e=>setActive(e,menu.menu_id)} target={menu.target}>{lang(menu.menu_th,menu.menu_en)}</a>
                              {
                                (menu.child.length>0)?(
                                  <div className="submenu hidden group-hover:block">
                                    <ul>
                                    {menu.child.map((sub_menu, sub_menu_key) =>(
                                      <li key={"mobile-sub_menu"+sub_menu_key} className={sub_menu.active?"active":''}>
                                      <a href={sub_menu.link} onClick={e=>setActive(e,sub_menu.menu_id)} target={sub_menu.target}>{lang(sub_menu.menu_th,sub_menu.menu_en)}</a>
                                      </li>
                                    ))}
                                    </ul>
                                  </div>
                                ):""
                              }
                              </li>
                          ))}
                          </ul>
                          <ul className="ul-menu-info">
                            <li className="info">
                              <a href={process.env.PUBLIC_URL + "/"} ><img src={logo} alt={lang(init.name_th,init.name_en)} width="102" height="96.25"/></a>
                              <h3>{lang(init.name_th,init.name_en)}</h3>
                            </li>
                            <li className="open">
                              <p>{lang('เวลาทำการ','OPENING HOUR')}: </p>
                              <p>{lang(init.open_info_th,init.open_info_en)}</p>
                            </li>
                            <li className="font-tool">
                              <p>{lang('ขนาดตัวอักษร','Font Size')}: </p>
                              <ul className="font-size">
                                  <li className={font_size==="small"?'active':''}><button onClick={()=>setFont("small")}>ก</button></li>
                                  <li className={font_size==="normal"?'active':''}><button onClick={()=>setFont("normal")}>ก</button></li>
                                  <li className={font_size==="large"?'active':''}><button onClick={()=>setFont("large")}>ก</button></li>
                              </ul>
                            </li>
                            <li className="social">
                              <ul>
                                  <li><a href={init.facebook} target="_blank" rel="noreferrer" className="rounded-btn"><FaFacebookF /></a></li>
                                  <li><a href={init.line} target="_blank" rel="noreferrer" className="rounded-btn"><BsLine /></a></li>
                                  <li><a href={init.twitter} target="_blank" rel="noreferrer" className="rounded-btn"><FaTwitter /></a></li>
                                  <li><a href={init.tiktok} target="_blank" rel="noreferrer" className="rounded-btn"><FaTiktok /></a></li>
                                  <li><a href={init.youtube} target="_blank" rel="noreferrer" className="rounded-btn"><FaYoutube /></a></li>
                                  <li><a href={`mailto:`+init.email} className="rounded-btn"><FaEnvelope /></a></li>
                                  <li><a href={`tel:`+init.phone} className="rounded-btn"><FaPhoneAlt /></a></li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="desktop-menu">
                        <ul>
                        { menus.map((menu, key) =>(
                            <li key={"menu"+key} className={menu.active?"group active":"group"}>
                            <a href={process.env.PUBLIC_URL + menu.link} target={menu.target}>{lang(menu.menu_th,menu.menu_en)}</a>
                            {
                              (menu.child.length>0)?(
                                <div className="submenu invisible group-hover:visible">
                                  <ul>
                                  {menu.child.map((sub_menu, sub_menu_key) =>(
                                    <li className={sub_menu.active?"active":''} key={"sub_menu"+sub_menu_key} style={{"background":header_color}}>
                                    <a href={process.env.PUBLIC_URL + sub_menu.link} target={sub_menu.target}>{lang(sub_menu.menu_th,sub_menu.menu_en)}</a>
                                    </li>
                                  ))}
                                  </ul>
                                </div>
                              ):""
                            }
                            </li>
                        ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </nav>
          :''}
      </header>
      {/* <section style={{"background":bg_color}}> */}
          {(init.name_th!==undefined)?
          <Suspense fallback={<Preloader text="" />}>
            <Outlet />
          </Suspense>
          :''}
          {/* <!--begin::Scrolltop Button--> */}
          <ScrollToTop />
          {/* <!--end::Scrolltop Button--> */}
      {/* </section> */}
          {(init.name_th!==undefined)?
      <div className={footer_styles.footer}>
        <Footer init={init} styles={footer_styles}/>
      </div>
          :''}
      <CookieBanner />
      </div>
    );
}

export default Layout