import { useState, useEffect, lazy, Suspense } from "react"
import { useParams } from 'react-router'
import { chk_img, lang } from '../components/lang'
import api from '../components/api'
import Callback from '../components/Evaluative/Callback'
import styles from '../assets/css/article.module.css'

const Form = lazy(() => import ('../components/Evaluative/Form'))

export default function Evaluative(props) {
    const [form_id, setFormID] = useState(0);
    const params = useParams();

    useEffect(()=>{
        if(params.formId){
            setFormID(params.formId);
        }else{
          if(props.id){
            setFormID(props.id);
          }else{
            setFormID(0);
          }
        }
        window.addEventListener("scroll", reveal);
        reveal();
    },[]);

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 150;
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
    }

    return (
        <div className={styles["article-container"]}>
              <div className={"content reveal"}>
                  {(form_id===0)?(
                    <article className={styles["article"]}>
                        <div className={styles["article-content"]}>
                            <h3>{lang("กรุณาเลือกแบบฟอร์ม","Please Select Evaluative Form")}</h3>
                        </div>
                    </article>
                  ):(
                    <Suspense fallback={<h1>Loading Article</h1>}>
                        <Form styles={styles} form_id={form_id} />
                    </Suspense>
                  )}
              </div>
        </div>
    )
}