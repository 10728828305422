import axios from 'axios';
import {api_url} from './lang';
const client = "faculty_pharmacy";
const token = encodeURIComponent(window.btoa(client));
const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'Authorization': 'Bearer '+token.toString()
}

export default axios.create({
  baseURL: api_url,
  headers: headers
});