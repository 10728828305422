import React from 'react';

export default function FieldValue(props) {

    function filter_list(data) {
        if(props.keyword!==''){
            const highlighted = '<mark>' + props.keyword.toLowerCase() + '</mark>';
            var RE = new RegExp(props.keyword, "ig");
            return data?.replaceAll(RE, highlighted);
        }else{
            return data;
        }
    }

    return (
        <>
            <p dangerouslySetInnerHTML={{__html:filter_list(props.value)}}></p>
        </>
    );
}