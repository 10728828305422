import { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom';
import { lang, img_url, chk_img, gotoLink, thDateFormat, enDateFormat } from '../lang'
import api from '../api'
import classes from '../../assets/css/calendar.module.css'
import Calendar from "../Booking/Calendar"
import DayCalendar from "../Booking/DayCalendar"
import Alert from "sweetalert2";

export default function HomeCalendar(props) {
    const {styles, init} = props;
    const [vdo, setVdo] = useState([]);
    const navigate = useNavigate();

    const [bookings, setBookings] = useState([]);
    const [events, setEvents] = useState([]);
    const [calendar_mode, setCalendarMode] = useState("monthly"); 
    const [rooms, setRooms] = useState([]);
    const [select_date, setSelectDate] = useState(new Date().toISOString().split('T')[0]);

    useEffect(()=>{
        const fetchInit = async () =>{
            try {
                const room_response = await api.get('/room');
                setRooms(room_response.data.results);
                const response = await api.get('/vdos/1?show=1');
                setVdo(response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchInit();
        fetchData();
    },[]);

    const fetchData = async () =>{
        try {
            const response = await api.get('/rsvn');
            const results = response.data.results;
            let all_events = [];
            results.forEach(element => {
                let item = {
                    title: lang(element.room_name,element.room_name_en),
                    start  : element.booking_date+'T'+element.start_time+':00',
                    end  : element.booking_date+'T'+element.end_time+':00',
                    item  : element,
                    type  : "booking",
                    allDay : false

                }
                all_events.push(item);
            });
            setBookings(results);


            
            const holiday_response = await api.get('/holiday');
            const holiday_results = holiday_response.data.results;
            holiday_results.forEach(element => {
                var end_date = new Date(element.end);
                let item = {
                    title: lang(element.detail,element.detail_en),
                    start  : new Date(element.start),
                    end  : end_date.setDate(end_date.getDate() + 1),
                    item  : element,
                    type  : "holiday",
                    allDay : true

                }
                all_events.push(item);
            });
            setEvents(all_events);
        } catch (err) {
            if(err.response){
                //Not in 202 response range
                console.log(err.response.data);
                console.log(err.response.headers);
                console.log(err.response.status);
            } else {
                console.log(`Error: ${err.message}`);
            }
        }
    }

    const dateClick = (info) => {
        // console.log(new Date(info.dateStr+" 12:00:00"));
        setSelectDate(new Date(info.dateStr+" 12:00:00"));

        setCalendarMode("daily");
    }
    
    /**
     * when we click on event we are displaying event details
     */
    const eventClick = (eventClick) => {
        if(eventClick.event.extendedProps.type==="booking"){
            let item = eventClick.event.extendedProps.item;
            let more_detail = '';
            if(item.title){
                more_detail = `
                <tr>
                    <td>${lang('รายละเอียด','Info')}</td>
                    <td><strong>${item.title}</strong></td>
                </tr>`;
            }
            Alert.fire({
                title: eventClick.event.title,
                html:
                    `<div class="table-responsive">
                <table class="table">
                <tbody>
                    <tr>
                        <td>${lang('รายการ','Title')}</td>
                        <td><strong>` + eventClick.event.title + `</strong></td>
                    </tr>
                    <tr>
                        <td>${lang('วันที่','Date')}</td>
                        <td><strong>` + lang(thDateFormat(item.booking_date),enDateFormat(item.booking_date)) + `</strong></td>
                    </tr>
                    <tr>
                        <td>${lang('เวลา','Time')}</td>
                        <td><strong>` + item.start_time + ' - ' + item.end_time + `</strong></td>
                    </tr>
                    <tr>
                        <td>${lang('จองโดย','Booking by')}</td>
                        <td><strong>` + item.booking_by + `</strong></td>
                    </tr>
                    <tr>
                        <td>${lang('เหตุผล','reason')}</td>
                        <td><strong>` + item.reason + `</strong></td>
                    </tr>` + more_detail + `
                </tbody>
                </table>
                </div>`,

                showDenyButton: false,
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                denyButtonColor: "#d33",
                confirmButtonText: lang("ปิดหน้าต่าง","Close"),
                cancelButtonText: lang("กลับหน้าหลัก","Back"),
                denyButtonText: lang("ยกเลิกการจอง","Cancel"),
            }).then(result => {
                // if (result.isConfirmed) {
                // //   eventClick.event.remove(); // It will remove event from the calendar
                //     openModal(item.id);
                // }else if (result.isDenied) {
                //     //   eventClick.event.remove(); // It will remove event from the calendar
                //         openRmModal(item.id);
                //     }
            });
        }else{
            let item = eventClick.event.extendedProps.item;
            let holiday = lang(thDateFormat(item.start),enDateFormat(item.start)) + ' - ' + lang(thDateFormat(item.end),enDateFormat(item.end));
            if(item.start === item.end){
                holiday = lang(thDateFormat(item.start),enDateFormat(item.start));
            }
            Alert.fire({
                title: eventClick.event.title,
                html:
                    `<div class="table-responsive">
                <table class="table">
                    <tbody>
                        <tr>
                            <td>${lang('รายการ','Title')}</td>
                            <td><strong>` + eventClick.event.title + `</strong></td>
                        </tr>
                        <tr>
                            <td>${lang('วันที่','Date')}</td>
                            <td><strong>` + holiday + `</strong></td>
                        </tr>
                    </tbody>
                </table>
                </div>`,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: "#3085d6",
                cancelButtonText: lang("ปิดหน้าต่าง","Close")
            }).then(result => {
            });
        }
    };

    // a custom render function
    function renderEventContent(eventInfo) {
        var e = document.getElementsByClassName('fc-col-header-cell-cushion');
        if(e){
            Array.from(e).forEach(element => {
                if(element.localName==="a"){
                    let d = document.createElement('div');
                    d.innerHTML = element.innerHTML;
                    element.parentNode.innerHTML=d.innerHTML;
                }
            });
        }
        let type = eventInfo.event.extendedProps.type;
        return (
        <>
            {(type==='booking')?<div className="fc-event-dot"></div>:''}
            <div className="fc-event-time">{eventInfo.timeText}</div>
            <div className="fc-event-title">{eventInfo.event.title}</div>
        </>
        )
    }

  return (
    <div className={"container "+styles["calendar"]}>
        <div className={styles["calendar-detail"]}>
            <div className={styles["vdo"]}></div>
            <div className={styles["calendar-group"]}>
                <h2>{lang("ตารางการใช้","Room Reservation ")}<span>{lang("ห้องอ่านเดี่ยว/ห้องอ่านกลุ่ม","Calendar")}</span></h2>
                <div className={classes["calendar"]+" calendar "+styles["frame"]}>
                    {(calendar_mode==="daily")?(
                        <DayCalendar select_date={select_date} styles={classes} showButton={true}
                            rooms={rooms} all_events={events} setCalendarMode={setCalendarMode}
                        />
                    ):(
                        <Calendar setCalendarMode={setCalendarMode} events={events} showButton={true} eventClick={eventClick} renderEventContent={renderEventContent} dateClick={dateClick}/>
                    )}
                </div>
            </div>
        </div>
        <div className={styles["vdo-detail"]+" reveal"}>
            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fpharmacylibcmu&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=997494168197506" width="340" height="550" style={{"border":"none","overflow":"hidden"}} scrolling="no" frameBorder={0} allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        </div>
    </div>
  )
}
