import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { lang, img_url, month_th_mini, month_en_mini } from './../lang'

export default function NewsCard(props) {
  const [day, setDay] = useState(1);
  const [month, setMonth] = useState(lang(month_th_mini[0],month_en_mini[0]));
  const [year, setYear] = useState(2022);
	const navigate = useNavigate();
  const item = props.item;
  const styles = props.styles;

  useEffect(()=>{
    let event_date = item.create_time;
      if(event_date!==null){
        setDay(event_date.substr(8,2));
        let event_month = Number(event_date.substr(5,2))-1;
        let month_name = month_en_mini[event_month];
        setMonth(month_name);
        setYear(event_date.substr(0,4));
    }
    if(item.detail!==null){
      event_date = JSON.parse(item.detail);
      console.log(event_date);
      if(event_date!==null&&event_date.start_date){
        setDay(event_date.start_date.substr(8,2));
        let event_month = Number(event_date.start_date.substr(5,2))-1;
        let month_name = month_en_mini[event_month];
        setMonth(month_name);
        setYear(event_date.start_date.substr(0,4));
      }
    }
  },[]);

  if(item.id===undefined){
    return null;
  }
  return (
    <div className={styles["card"]}>
      <div className={styles["image"]} onClick={() => navigate(item.url)}>
        <div className={styles["image-inner"]} style={{ backgroundImage: `url(${img_url + item.cover_img})` }}></div>
        <div className={styles["shine-box"]} />
      </div>
      <div className={styles["info"]}>
        {/* <div className={styles["date"]}>
          <div className={styles["day"]}>{day}</div>
          <div className={styles["month"]}>{month}</div>
          <div className={styles["year"]}>{year}</div>
        </div> */}
        <div className={styles["title"]} onClick={() => navigate(item.url)}><h3>{lang(item.title_th,item.title_en)}</h3></div>
        <div className={styles["detail"]}>{lang(item.info_th,item.info_en)}</div>
        <div className={styles["viewmore"]} onClick={() => navigate(item.url)}>{lang('รายละเอียด','More Details')}</div>
      </div>
      <div className="card-end"></div>
    </div>
  );
}
